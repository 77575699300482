import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentComponentState } from "../../../components";

export type PaymentState = {
  internalState: PaymentComponentState | undefined;
  isPending?: boolean;
};

export const initialState: PaymentState = {
  internalState: {},
  isPending: false,
};

export const paymentSlice = createSlice({
  name: "paymentState",
  initialState,
  reducers: {
    setInternalContextState(
      state,
      { payload }: PayloadAction<PaymentComponentState>
    ) {
      state.internalState = payload;
    },
    setIsPending(state, { payload }: PayloadAction<boolean>) {
      state.isPending = payload;
    },
  },
});

export const { setInternalContextState, setIsPending } = paymentSlice.actions;
