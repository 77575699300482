import { AxiosRequestConfig } from "axios";
import {
  DEFAULT_UNAUTHORIZED_REQUEST_RETRIES,
  DEFAULT_UNAUTHORIZED_REQUEST_RETRY_DELAY,
  HTTP_HEADER_AUTHORIZATION,
  REQUEST_CONFIG_NAMESPACE,
} from "./constants";
import { DecoratedHttpClient } from "./decorated-http-client";
import {
  HttpClient,
  HttpErrorHandler,
  RetriableAxiosRequestConfig,
  RetryDelayCalculator,
  TokenRefresher,
} from "./types";

/**
 * A Token Handling HTTP Client is intended to abstract the concern of properly
 * injecting a bearer token in each outgoing request.
 *
 * It also provides an optional feature to retry any requests that fail due to a
 * 410 Unauthorized HTTP status code.
 *
 * By default the retry behavior is not enabled.
 *
 * The constructor arguments can be used to enable this behavior and to
 * customize it as needed.
 */
export class TokenHandlingHttpClient extends DecoratedHttpClient {
  /**
   * Construct a Token Handling HTTP Client with some configuration parameters.
   *
   * @param token A bearer token to be set in the Authorization HTTP header
   * of all outgoing requests that are initiated using this HTTP client instance.
   * @param getFreshToken A function that the instance will use to get a fresh
   * token to retry a request that failed with a 401 error.
   * @param onRetryError A function that the instance will call if there is any
   * error during the retry logic.
   * @param retries The number of times that this instance will retry a failed
   * request.
   * @param retryDelay The millisecond delay between each retry. This can either
   * be an absolute value. Or it can be a function that takes he current retry
   * count and returns an appropriate delay value based on that. Such a function
   * can be used to implement an exponential back-off strategy for the retry logic.
   * @param httpClient A custom HTTP Client instance to be used by this instance
   * instead of plain on that it constructs on its own by default.
   *
   * @example
   *
   * ```js
   * const httpClient = new TokenHandlingHttpClient(
   *  "KJ98HG",
   *  () => Promise.resolve("JHS87JG"),
   *  console.error,
   *  3,
   *  (retryCount) => retryCount * 2
   * )
   *
   * httpClient.get("https://www.example.com")
   * ```
   */
  constructor(
    private token: string,
    private getFreshToken?: TokenRefresher,
    private onRetryError?: HttpErrorHandler,
    private retries: number = DEFAULT_UNAUTHORIZED_REQUEST_RETRIES,
    /**
     * @todo For now `retryDelay` is set to a simple absolute delay value. This
     * needs to be enhanced to a default delay calculation function that employs
     * a reasonable exponential back-off strategy.
     */
    private retryDelay:
      | number
      | RetryDelayCalculator = DEFAULT_UNAUTHORIZED_REQUEST_RETRY_DELAY,
    httpClient?: HttpClient
  ) {
    super(httpClient);
    this.interceptors.request.use(this.requestInterceptor);
    this.interceptors.response.use(undefined, this.responseInterceptor);
  }

  /**
   * This request interceptor sets the token in to the Authorization HTTP header
   * in the outgoing request.
   *
   * It also initializes the retry count in the request object.
   *
   * @param config The Axios config object with which a request is being
   * initiated.
   */
  private requestInterceptor = (config: AxiosRequestConfig) => {
    const retriableConfig = config as RetriableAxiosRequestConfig;

    retriableConfig.headers[HTTP_HEADER_AUTHORIZATION] = `Bearer ${this.token}`;

    if (retriableConfig[REQUEST_CONFIG_NAMESPACE]?.retryCount === undefined) {
      retriableConfig[REQUEST_CONFIG_NAMESPACE] = {
        ...retriableConfig[REQUEST_CONFIG_NAMESPACE],
        retryCount: 0,
      };
    }

    return retriableConfig;
  };

  /**
   * This response interceptor is responsible for checking if the response was
   * a 401 error response. If so it tries to:
   * 1. Checks if we're set up to get a fresh token. If not then simply reject
   *    the original promise.
   * 2. Checks if the we're within the set number of reties. If not then simply
   *    reject the original promise.
   * 3. Get a fresh token.
   * 4. Get a retry delay value.
   * 4. Increment the retry count in the request config.
   * 5. Set up to retry the request after the calculated retry delay with the
   *    updated request config.
   *
   * @param error The Axios error object
   */
  private responseInterceptor = async (error: any) => {
    if (error.response?.status === 401 && this.getFreshToken) {
      try {
        const retriableConfig = error.config as RetriableAxiosRequestConfig;
        const retryCount = retriableConfig[REQUEST_CONFIG_NAMESPACE].retryCount;

        if (retryCount < this.retries) {
          this.token = await this.getFreshToken();
          const retryDelay = this.getRetryDelay(retryCount);
          retriableConfig[REQUEST_CONFIG_NAMESPACE].retryCount += 1;

          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(this.request(retriableConfig));
            }, retryDelay);
          });
        }
      } catch (error) {
        this.onRetryError?.(error);
      }
    }
    return Promise.reject(error);
  };

  /**
   * This method calculates the retry delay based on the current retry count.
   *
   * @param retryCount The current retry count for which the delay is to be
   * calculated.
   */
  private getRetryDelay = (retryCount: number): number => {
    if (typeof this.retryDelay === "function") {
      return this.retryDelay(retryCount);
    }

    return this.retryDelay;
  };
}
