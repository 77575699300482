import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import merge from "lodash/merge";
import { AxiosInterceptors, HttpClient } from "./types";

/**
 * This abstract class is intended to act as the base class for all specific
 * HTTP client implementations provided by this library.
 *
 * This class implements the the `HttpClient`, thus all other concrete HTTP client
 * implementations that derive from it also end up adhering to this API interface,
 * which is a key design goal for this library.
 */
export abstract class DecoratedHttpClient<
  C extends AxiosRequestConfig = AxiosRequestConfig
> implements HttpClient<C> {
  /**
   * The internal HttpClient instance that is being decorated.
   */
  httpClient: HttpClient;

  /**
   * Concrete classes should set default Axios config specific to their
   * implementation in to this property.
   *
   * They should then call `this.mergeDefaults()` in their constructor to safely
   * merge these with those on the httpClient (Axios instance).
   */
  defaults: C = {} as C;

  /**
   * Concrete classes should use this property to add any custom interceptors
   * specific to their implementation, in their constructor code.
   */
  interceptors: AxiosInterceptors;

  /**
   * This constructor will be inherited by any concrete classes extending this
   * class.
   *
   * However, most concrete classes would need to provide their own constructor
   * in order to implement custom logic for constructing the Axios instance in
   * the desired manner.
   *
   * @param httpClient If passed, this Axios instance will be used instead of
   * creating one.
   */
  constructor(httpClient?: HttpClient) {
    this.httpClient = httpClient ?? Axios.create();
    this.mergeDefaults();
    this.interceptors = this.httpClient.interceptors;
  }

  /**
   * This is a helper function that safely merges concrete class specific
   * config defaults with those on the httpClient (Axios instance).
   *
   * Merging own config defaults with those on the httpClient (Axios instance)
   * must be done using deep merge otherwise Axios does not seem to honor the
   * overrides.
   *
   * Also, the result must also be assigned to the concrete class `defaults`
   * property in order for chains of decorated concrete classes to also work
   * properly.
   */
  mergeDefaults(): void {
    this.defaults = merge(this.httpClient.defaults, this.defaults);
  }

  /**
   * Get a fully formed URI with with an HTTP request will be sent. This is based
   * on the `config` passed as well the HttpClient instance `defaults`.
   *
   * @param config AxiosRequestConfig object.
   */
  getUri(config?: C): string {
    return this.httpClient.getUri(config);
  }

  /**
   * Sends an HTTP request as per the `config` passed.
   *
   * @param config AxiosRequestConfig object. If passed, it is merged with the
   * `defaults` on the HttpClient instance. If not passed, then simply uses the
   * `defaults.`
   */
  request<T = any>(config: C): Promise<AxiosResponse<T>> {
    return this.httpClient.request(config);
  }

  /**
   * Sends an HTTP GET request as per the `config` passed.
   *
   * @param url The URL to call.
   * @param config AxiosRequestConfig object.
   */
  get<T = any>(url: string, config: C = {} as C): Promise<AxiosResponse<T>> {
    const ownConfig: AxiosRequestConfig = { url, method: "get" };
    const requestConfig = merge(config, ownConfig);
    return this.request(requestConfig);
  }

  /**
   * Sends an HTTP HEAD request as per the `config` passed.
   *
   * @param url The URL to call.
   * @param config AxiosRequestConfig object.
   */
  head<T = any>(url: string, config: C = {} as C): Promise<AxiosResponse<T>> {
    const ownConfig: AxiosRequestConfig = { url, method: "head" };
    const requestConfig = merge(config, ownConfig);
    return this.request(requestConfig);
  }

  /**
   * Sends an HTTP OPTIONS request as per the `config` passed.
   *
   * @param url The URL to call.
   * @param config AxiosRequestConfig object.
   */
  options<T = any>(
    url: string,
    config: C = {} as C
  ): Promise<AxiosResponse<T>> {
    const ownConfig: AxiosRequestConfig = { url, method: "options" };
    const requestConfig = merge(config, ownConfig);
    return this.request(requestConfig);
  }

  /**
   * Sends an HTTP DELETE request as per the `config` passed.
   *
   * @param url The URL to call.
   * @param data Request data object.
   * @param config AxiosRequestConfig object.
   */
  delete<D = any, T = any>(
    url: string,
    data?: D,
    config: C = {} as C
  ): Promise<AxiosResponse<T>> {
    const ownConfig: AxiosRequestConfig = { url, method: "delete", data };
    const requestConfig = merge(config, ownConfig);
    return this.request(requestConfig);
  }

  /**
   * Sends an HTTP POST request as per the `config` passed.
   *
   * @param url The URL to call.
   * @param data Request data object.
   * @param config AxiosRequestConfig object.
   */
  post<D = any, T = any>(
    url: string,
    data?: D,
    config: C = {} as C
  ): Promise<AxiosResponse<T>> {
    const ownConfig: AxiosRequestConfig = { url, method: "post", data };
    const requestConfig = merge(config, ownConfig);
    return this.request(requestConfig);
  }

  /**
   * Sends an HTTP PUT request as per the `config` passed.
   *
   * @param url The URL to call.
   * @param data Request data object.
   * @param config AxiosRequestConfig object.
   */
  put<D = any, T = any>(
    url: string,
    data?: D,
    config: C = {} as C
  ): Promise<AxiosResponse<T>> {
    const ownConfig: AxiosRequestConfig = { url, method: "put", data };
    const requestConfig = merge(config, ownConfig);
    return this.request(requestConfig);
  }

  /**
   * Sends an HTTP PATCH request as per the `config` passed.
   *
   * @param url The URL to call.
   * @param data Request data object.
   * @param config AxiosRequestConfig object.
   */
  patch<D = any, T = any>(
    url: string,
    data?: D,
    config: C = {} as C
  ): Promise<AxiosResponse<T>> {
    const ownConfig: AxiosRequestConfig = { url, method: "patch", data };
    const requestConfig = merge(config, ownConfig);
    return this.request(requestConfig);
  }
}
