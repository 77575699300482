import { Dispatch, useCallback } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { CscPaymentsJSClient } from "@ftdr/cscpayments-js-client";
import {
  ValidationRoutingNumberRequest,
  ValidationRoutingNumberResponse,
} from "@ftdr/cscpayments-js-client/lib/types/types";
import { usePaymentContext } from "../../payment-context";
import {
  FailureResponseType,
  FailureTypeValue,
  SuccessTypeValue,
} from "../../../components/payment-processors/types";
import { useAppContext } from "@ftdr/blueprint-components-react";
import { setError, setValidationNumberResponseState } from "../state";

export type ValidationRoutingNumberResult = {
  result?: ValidationRoutingNumberResponse;
  error?: FailureResponseType;
};

export type ValidationRoutingNumber = (
  requestData: ValidationRoutingNumberRequest
) => Promise<ValidationRoutingNumberResult | undefined>;

export type UseValidationRoutingNumber = {
  validationRoutingNumber: ValidationRoutingNumber;
};

export const useValidationRoutingNumber = (
  dispatch: Dispatch<AnyAction>,
  client: CscPaymentsJSClient | undefined
): UseValidationRoutingNumber => {
  const {
    appSettings: { localizedText },
  } = useAppContext();
  const { onFailure, onResponse } = usePaymentContext();

  const validationRoutingNumber = useCallback(
    async (
      requestData: ValidationRoutingNumberRequest
    ): Promise<ValidationRoutingNumberResult> => {
      let result = undefined;
      try {
        result = await client?.validationRoutingNumber(requestData);
        dispatch(setValidationNumberResponseState(result?.data));
        result?.data?.data[0]?.routingNumberIsValid
          ? onResponse({
              type: SuccessTypeValue.VALID_ROUTING_NUMBER,
              successValidationResponse: result?.data,
            })
          : onFailure({
              type: FailureTypeValue.NOT_VALID_ROUTING_NUMBER,
              failureValidationResponse: result?.data,
            });
        return { result: result?.data };
      } catch (ex) {
        const errorStatusCode = ex?.lastError?.response?.status;
        const errorObject = {
          isError: true,
          message: errorStatusCode
            ? localizedText(`ERROR_STATUS_CODE_${errorStatusCode}`)
            : localizedText(`NETWORK_ERROR`),
        };
        dispatch(setError(errorObject));
        const errorResult = {
          type: FailureTypeValue.ACH_FAILURE,
          achFailureResponse: {
            message: errorObject.message,
            rawError: ex,
          },
        };
        onFailure(errorResult);
        return { error: { ...errorResult } };
      }
    },
    [client, onFailure, localizedText, onResponse, dispatch]
  );
  return { validationRoutingNumber };
};
