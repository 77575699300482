import { encode } from "@protobufjs/base64";

const REG_EXP_TRAILING_PADDING_CHARACTERS = /=+$/;
const REG_EXP_ALL_PLUS_CHARACTERS = /\+/g;
const REG_EXP_ALL_SLASH_CHARACTERS = /\//g;

/**
 * A function to encode binary data using the "base64url without padding"
 * encoding scheme - as per the RFC-4648 standard.
 *
 * You can read more about this by following these links:
 *
 * @see {@link https://tools.ietf.org/html/rfc4648#section-5}
 * @see {@link https://tools.ietf.org/html/rfc4648#section-3.2}
 *
 * It uses the [@protobufjs/base64]{@link https://www.npmjs.com/package/@protobufjs/base64}
 * package for the underlying base64 encoding implementation.
 *
 * @param buffer A byte array representing the data to be encoded
 * @returns base64url encoded string without padding
 */
export const base64urlEncodeWithoutPadding = (buffer: Uint8Array): string =>
  encode(buffer, 0, buffer.length)
    .replace(REG_EXP_TRAILING_PADDING_CHARACTERS, "")
    .replace(REG_EXP_ALL_PLUS_CHARACTERS, "-")
    .replace(REG_EXP_ALL_SLASH_CHARACTERS, "_");
