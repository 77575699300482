import {
  Config,
  DetermineRedirectFunc,
  Hub,
  OIDCService,
  OidcUser,
} from "@ftdr/pkce-js";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface AuthContextValue {
  callback: (determineRedirect?: DetermineRedirectFunc) => void;
  handleReq: (
    request: AxiosRequestConfig,
    useIdToken?: boolean
  ) => Promise<AxiosResponse>;
  login: (declaredProvider: string, options?: LoginOptions) => void;
  logout: () => void;
  oidcUser?: OidcUser | null | undefined;
  renewToken: () => Promise<OidcUser | null>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFn = () => {};

export const AuthContext = createContext<AuthContextValue>({
  callback: emptyFn,
  handleReq: async (request: AxiosRequestConfig) => ({
    config: request,
    data: undefined,
    headers: undefined,
    status: 0,
    statusText: "",
  }),
  login: emptyFn,
  logout: emptyFn,
  renewToken: async () => null,
});

interface AuthProviderProps {
  children?: ReactNode;
  serviceConfigs: Config[];
}

// for now, only support email extraQueryParam
export interface LoginOptions {
  extraQueryParams?: {
    email?: string;
  };
}

export const AuthProvider: FC<AuthProviderProps> = ({
  children,
  serviceConfigs,
}) => {
  if (serviceConfigs.length < 1) {
    throw new Error(
      "@ftdr/use-auth::AuthProvider - no service configs provided"
    );
  }
  const [oidcHub] = useState<Hub>(
    new Hub(serviceConfigs.map((config) => new OIDCService(config)))
  );
  const [debug] = useState(
    serviceConfigs.reduce((acc, config) => acc || config.debug, false)
  );

  const [oidcUser, setOidcUser] = useState<OidcUser | null | undefined>(
    undefined
  );
  const [provider, setProvider] = useState<string>(serviceConfigs[0].nickname);

  useEffect(() => {
    if (debug) {
      console.log("@ftdr/use-auth::useEffect - checking localStorage for user");
    }
    const loadUser = async () => {
      let userFound = false;
      for (const [name, service] of oidcHub.serviceMap) {
        const user = await service.getUser();
        if (user && !user.expired) {
          setOidcUser(user);
          setProvider(name);
          userFound = true;
        }
      }
      if (!userFound) {
        setOidcUser(null);
      }
    };
    loadUser();
  }, [debug, oidcHub]);

  // other functions make calls to the service. getService makes sure they
  // obtain the correct service when there are multiple
  const getService = (declaredProvider?: string): OIDCService => {
    const name = declaredProvider || provider;
    const service = oidcHub.serviceMap.get(name);

    if (!service) {
      throw new Error(
        "@ftdr/use-auth::getService - Error accessing OIDC service"
      );
    }
    return service;
  };

  const login = (declaredProvider: string, options?: LoginOptions): void => {
    // prevent login when user check is incomplete
    if (oidcUser === undefined) {
      if (debug) {
        console.log(
          "@ftdr/use-auth::login - cannot invoke login for undefined user"
        );
      }
      return;
    }

    const service = getService(declaredProvider);

    // attach email as query param to allow single login flow
    if (options?.extraQueryParams) {
      if (debug) {
        console.log(
          "@ftdr/use-auth::login - setting extraQueryParams: ",
          JSON.stringify(options.extraQueryParams)
        );
      }
      const settings = service.settings();
      settings.extraQueryParams = {
        ...settings.extraQueryParams,
        ...options.extraQueryParams,
      };
    }

    service.login().then(() => {
      setProvider(declaredProvider);
    });
  };

  const logout = (): void => {
    // prevent invoking logout for users who are not logged in
    if (!oidcUser || oidcUser.expired) {
      if (debug) {
        console.log(
          "@ftdr/use-auth::logout - cannot invoke logout without current oidcUser"
        );
      }
      return;
    }

    getService().logout();
  };

  const callback = (
    determineRedirect?: DetermineRedirectFunc | string
  ): void => {
    const redirectFunc =
      typeof determineRedirect === "string"
        ? () => determineRedirect
        : determineRedirect;
    oidcHub.callback(redirectFunc);
  };

  const handleReq = (
    request: AxiosRequestConfig,
    useIdToken = true
  ): Promise<AxiosResponse> => {
    return getService().handleRequest(useIdToken, request);
  };

  const renewToken = async (): Promise<OidcUser> => {
    try {
      const oidcUser = await getService().renewToken();
      setOidcUser(oidcUser);
      return oidcUser;
    } catch (e) {
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        callback,
        handleReq,
        login,
        logout,
        renewToken,
        oidcUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextValue => useContext(AuthContext);

/* eslint-disable */
export const withAuth = <P extends object>(Comp: React.ComponentType<P>) => (
  props: P
) => {
  const auth = useAuth();
  return <Comp auth={auth} {...(props as P)} />;
};
