export const HTTP_HEADER_ACCEPT = "Accept";
export const HTTP_HEADER_AUTHORIZATION = "Authorization";
export const HTTP_HEADER_CONTENT_TYPE = "Content-Type";

export const HTTP_MEDIA_TYPE_JSON = "application/json; charset=utf-8";
export const HTTP_MEDIA_TYPE_PROTOBUF = "application/x-protobuf";

export const REQUEST_CONFIG_NAMESPACE = "ftdrHttpClient";

export const DEFAULT_UNAUTHORIZED_REQUEST_RETRIES = 0;
export const DEFAULT_UNAUTHORIZED_REQUEST_RETRY_DELAY = 0;
