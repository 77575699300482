import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import merge from "lodash/merge";
import {
  HTTP_HEADER_ACCEPT,
  HTTP_HEADER_CONTENT_TYPE,
  HTTP_MEDIA_TYPE_PROTOBUF,
  REQUEST_CONFIG_NAMESPACE,
} from "./constants";
import { DecoratedHttpClient } from "./decorated-http-client";
import {
  EncodableProtobufMessage,
  HttpClient,
  ProtobufAxiosRequestConfig,
} from "./types";
import { base64urlEncodeWithoutPadding } from "./utils";

export class ProtobufHttpClient extends DecoratedHttpClient<ProtobufAxiosRequestConfig> {
  defaults: ProtobufAxiosRequestConfig = {
    responseType: "arraybuffer",
    headers: {
      common: {
        [HTTP_HEADER_ACCEPT]: HTTP_MEDIA_TYPE_PROTOBUF,
      },
      delete: {
        [HTTP_HEADER_CONTENT_TYPE]: HTTP_MEDIA_TYPE_PROTOBUF,
      },
      post: {
        [HTTP_HEADER_CONTENT_TYPE]: HTTP_MEDIA_TYPE_PROTOBUF,
      },
      put: {
        [HTTP_HEADER_CONTENT_TYPE]: HTTP_MEDIA_TYPE_PROTOBUF,
      },
      patch: {
        [HTTP_HEADER_CONTENT_TYPE]: HTTP_MEDIA_TYPE_PROTOBUF,
      },
    },
  };

  constructor(httpClient?: HttpClient) {
    super(httpClient);
    this.mergeDefaults();
  }

  /**
   * Sends an HTTP request as per the `config` passed. It additionally sets up
   * common, request pre-processing and response post-processing logic to handle
   * protobuf encoding/decoding.
   *
   * @param config AxiosRequestConfig object. If passed, it is merged with the
   * `defaults` on the HttpClient instance. If not passed, then simply uses the
   * `defaults.`
   */
  request = <T = any>(
    config: ProtobufAxiosRequestConfig
  ): Promise<AxiosResponse<T>> => {
    const retryCount = config[REQUEST_CONFIG_NAMESPACE]?.retryCount ?? 0;

    /**
     * Do not attempt to encode/decode when the request is being retried within
     * a possible retry loop. A retry loop could be introduced in case this HTTP
     * client is composed with another one that implements a retry logic, like
     * the TokenHandlingHttpClient.
     */
    if (retryCount > 0) {
      return this.httpClient.request(config);
    }

    const configWithEncodedRequestData = getConfigWithEncodedRequestData(
      config
    );

    const requestConfig = merge(config, configWithEncodedRequestData);

    return this.httpClient
      .request(requestConfig)
      .then(decodeResponseData)
      .catch(decodeResponseError);
  };

  private requestWithRequestDataAsUrlParam = <D = any, T = any>(
    ownConfig: AxiosRequestConfig,
    data?: D,
    config: ProtobufAxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> => {
    const configWithParams = getConfigWithEncodedRequestDataAsUrlParams(
      data,
      config
    );

    const requestConfig = merge(config, configWithParams, ownConfig);

    return this.httpClient
      .request(requestConfig)
      .then(decodeResponseData)
      .catch(decodeResponseError);
  };

  /**
   * Sends an HTTP GET request as per the `config` passed, and request data is
   * sent as a URL param.
   *
   * @param url The URL to call.
   * @param data Request data object, it will be sent as a URL param.
   * @param config AxiosRequestConfig object.
   */
  getWithRequestDataAsUrlParam = <D = any, T = any>(
    url: string,
    data?: D,
    config: ProtobufAxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> => {
    const ownConfig: AxiosRequestConfig = { url, method: "get" };

    return this.requestWithRequestDataAsUrlParam(ownConfig, data, config);
  };

  /**
   * Sends an HTTP DELETE request as per the `config` passed, and request data is
   * sent as a URL param.
   *
   * @param url The URL to call.
   * @param data Request data object, it will be sent as a URL param.
   * @param config AxiosRequestConfig object.
   */
  deleteWithRequestDataAsUrlParam = <D = any, T = any>(
    url: string,
    data?: D,
    config: ProtobufAxiosRequestConfig = {}
  ): Promise<AxiosResponse<T>> => {
    const ownConfig: AxiosRequestConfig = { url, method: "delete" };

    return this.requestWithRequestDataAsUrlParam(ownConfig, data, config);
  };
}

const getConfigWithEncodedRequestData = <M>(
  config: ProtobufAxiosRequestConfig
): AxiosRequestConfig | undefined => {
  const requestProtobufModel =
    config[REQUEST_CONFIG_NAMESPACE]?.requestProtobufModel;

  const requestData = config.data;

  if (requestProtobufModel && requestData) {
    const { buffer, byteOffset, byteLength } = encodeRequestData<M>(
      requestData,
      requestProtobufModel
    );

    /**
     * We should ideally be able to simply return `buffer`.
     * However `protobufjs` does not seem to like that.
     * So we perform the below no-op on the `buffer` and return the result.
     * This, for some reason, seems to do the trick. ¯\_(ツ)_/¯
     */
    const encodedRequestData = buffer.slice(
      byteOffset,
      byteOffset + byteLength
    );

    return {
      data: encodedRequestData,
    };
  }

  return undefined;
};

const getConfigWithEncodedRequestDataAsUrlParams = <M>(
  requestData: M,
  config: ProtobufAxiosRequestConfig
): AxiosRequestConfig | undefined => {
  const requestProtobufModel =
    config[REQUEST_CONFIG_NAMESPACE]?.requestProtobufModel;
  const requestDataUrlParamName =
    config[REQUEST_CONFIG_NAMESPACE]?.requestDataUrlParamName ?? "proto_body";

  if (requestProtobufModel && requestData) {
    const encodedRequestData = encodeRequestData<M>(
      requestData,
      requestProtobufModel
    );
    const base64EncodedData = base64urlEncodeWithoutPadding(encodedRequestData);
    return {
      params: {
        [requestDataUrlParamName]: base64EncodedData,
      },
    };
  }

  return undefined;
};

const encodeRequestData = <M>(
  requestData: M,
  requestProtobufModel: EncodableProtobufMessage<M>
) => {
  return requestProtobufModel.encode(requestData).finish();
};

export const decodeResponseData = (
  response: AxiosResponse<any>
): AxiosResponse<any> => {
  const responseProtobufModel = (response?.config as ProtobufAxiosRequestConfig)?.[
    REQUEST_CONFIG_NAMESPACE
  ]?.responseProtobufModel;

  if (response.data && responseProtobufModel) {
    response.data = responseProtobufModel.decode(new Uint8Array(response.data));
  }

  return response;
};

const decodeResponseError = (error: AxiosError<any>) => {
  const errorResponseProtobufModel = (error?.config as ProtobufAxiosRequestConfig)?.[
    REQUEST_CONFIG_NAMESPACE
  ]?.errorResponseProtobufModel;

  if (error.response?.data && errorResponseProtobufModel) {
    error.response.data = errorResponseProtobufModel.decode(
      new Uint8Array(error.response.data)
    );
  }

  throw error;
};
