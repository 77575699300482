import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ValidationRoutingNumberResponse } from "@ftdr/cscpayments-js-client/lib/types/types";

export type CscPaymentClientState = {
  isLoading?: boolean;
  validationNumberResponseState?: ValidationRoutingNumberResponse;
  error: { isError: boolean; message?: string };
  isCompleted?: boolean;
};

export const initialState: CscPaymentClientState = {
  isLoading: false,
  error: { isError: false, message: "" },
  isCompleted: false,
};

export const cscPaymentClientSlice = createSlice({
  name: "cscPaymentState",
  initialState,
  reducers: {
    setValidationNumberResponseState(
      state,
      { payload }: PayloadAction<ValidationRoutingNumberResponse | undefined>
    ) {
      state.error = { message: "", isError: false };
      state.validationNumberResponseState =
        payload || initialState.validationNumberResponseState;
    },
    setIsLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    setError(
      state,
      { payload }: PayloadAction<{ isError: boolean; message?: string }>
    ) {
      state.error = payload;
    },
  },
});

export const {
  setError,
  setIsLoading,
  setValidationNumberResponseState,
} = cscPaymentClientSlice.actions;
