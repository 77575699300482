import { useCallback } from "react";
import { useCscPaymentClientContext } from "../../../../../../contexts/csc-payments-client-context";

export type UseRoutingNumberValidationType = {
  routingNumberValidation: (
    bankRoutingNumber: string
  ) => Promise<boolean | undefined>;
};

export const useRoutingNumberValidation = (): UseRoutingNumberValidationType => {
  const { validationRoutingNumber } = useCscPaymentClientContext();
  const routingNumberValidation = useCallback(
    async (bankRoutingNumber: string) => {
      const result = await validationRoutingNumber({
        routingNumber: bankRoutingNumber,
      });
      return !!result?.result?.data[0]?.routingNumberIsValid;
    },
    [validationRoutingNumber]
  );
  return { routingNumberValidation };
};
